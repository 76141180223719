<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="card p-2">
      <TitleButton
          :showBtn="false"
          :showAddNew="false"
          title="Sales Commission Report Summary"
      />

      <div class="row mt-2">
        <div class="col-md-3">
          <label for="colFormLabel">
            Select Sales Person
          </label>
          <v-select
              placeholder="Select Sales Person"
              v-model="sales_person_id"
              :options="contacts"
              label="name"
              :reduce="name => name.id"
          />
        </div>
      </div>
      <div>
        <DateQuerySetter
          btnTitle="Go"
          @onClickGo="getSummary"
        />
      </div>
    </div>

    <div class="col-12">
      <div class="d-flex justify-content-end px-2">
        <button
          @click="exportTable"
          class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
        >
          Export
        </button>
      </div>
    </div>

    <div class="card mt-1 px-2">
      <SalesCommissionReportTable 
        :summary="summary"
      />
    </div>
    <div class="mb-2"></div>

    <div class="px-2 position-absolute bottom-0">
      <Pagination
        :offset="offset"
        @onPageChange="onPageChange"
        ref="pagination"
      />
    </div>
    <Loader v-if="loading"/>
  </div>
</template>

<script setup>

import { onMounted, computed, ref } from 'vue'
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import SalesCommissionReportTable from '@/components/molecule/company/sales-commission/SalesCommissionReportTable'
import Loader from '@/components/atom/LoaderComponent'
import handlePurchase from '@/services/modules/purchase'
import {useRoute, useRouter} from "vue-router";
import handleContact from '@/services/modules/contact'
import { useStore } from 'vuex'
import Pagination from '@/components/atom/Pagination'

const $router = useRouter();
const $route = useRoute();
const store = useStore();

const {fetchSalesCommissionReportSummary} = handlePurchase()
const {fetchContactProfiles} = handleContact()

const contacts = ref([]);
const offset = ref(20);
const sales_person_id = ref(null);
const summary = ref({});

const companyId = computed( () => $route.params.companyId);
const start = computed( () => $route.query.start);
const end = computed( () =>  $route.query.end);
const page = computed( () => $route.query.page);
const loading = ref(true);

const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
}

const resetPagination = () => {
  store.commit('resetPagination')
}

onMounted(() => {
  const companyQuery = `?company_id=${companyId.value}`;
  const contactRes = fetchContactProfiles(companyQuery)
  loading.value = true;

  contactRes.then(res=> {
    if(res.data) contacts.value = res.data
    loading.value = false;
  }).catch(e => {
    console.log('Something went wrong');
  })

})

const onPageChange = (page) => {
  let routeQuery = Object.assign({}, $route.query)
  routeQuery.page = page
  $router.push({path: $route.path, query: routeQuery})
  setTimeout(() => {
    getSummary()
  },100)
};

const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
};

const exportTable = () => {
  //todo
}

const getQuery = () => {
  let query = `?company_id=${companyId.value}&sales_person_id=${sales_person_id.value}`
  if(!start && !end) return query
  if(start) query += '&start_date='+start.value
  if(end) query += '&end_date='+end.value
  query += '&offset='+offset.value
  if(page) query += '&page='+page.value
  return query
}

const getSummary = () => {
  let query = getQuery();
  fetchSalesCommissionReportSummary(query).then((res) => {
    if(!res.status){
      return resetPagination();
    }
    summary.value = res.data.data;
    setPagination(res.data)
  })
}

onMounted( async () => {
  await getSummary();
})
</script>
