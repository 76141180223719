<template>
    <div style="min-height: 200px">
        <div class="table-responsive">
            <table id="trial" class="table table-bordered">
                <thead>
                    <tr>
                        <th>Sales Person Name</th>
                        <th>Mobile No</th>
                        <th>Email</th>
                        <th class="text-end">Total Sales Amount</th>
                        <th class="text-end">Total Commission Amount</th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr v-for="(item, index) in summary">
                        <td>{{ item.full_name }}</td>
                        <td>{{ item.profile_items[1].value }}</td>
                        <td>{{ item.profile_items[0].value }}</td>
                        <td class="text-end">{{ item.total_sales_amount ? commaFormat(item.total_sales_amount) : 0 }}</td>
                        <td class="text-end">{{ item.total_commission_amount ? commaFormat(item.total_commission_amount) : 0 }}</td>
                    </tr>
                </tbody>
            </table>
            <p v-if="summary.length == 0" class="mt-5 text-center">No data</p>
        </div>
    </div>
</template>

<script setup>
import figureFormatter from '@/services/utils/figureFormatter'

const {commaFormat} = figureFormatter ()

const props = defineProps({
    summary: {
        type: Array
    }
})

</script>

<style scoped>
#trial td{
    padding: 2px
}
#trial th{
    padding: 10px 3px;
}
</style>
